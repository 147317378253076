import {
  enableEncryptionMiddleware,
  settingTwoFactor,
  verifySettingMFA,
} from "../services/api"
import { useAppSelector } from "hooks/useApp"
import Button from "components/Button/Button"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"
import SwitchButtonDefault from "components/SwitchButton/SwitchButtonDefault"
import { EnableEncryptionRequest, MFAMethod, UserInfo } from "../types"
import { useBoolean, useString } from "helpers/hooks"
import YesNoModal from "../molecules/ConfirmationModal"
import Typography from "components/Typography"
import { ReactComponent as EncryptionLockIcon } from "assets/images/icons/enable-encryption-icon.svg"
import ModalCustom from "components/ModalCustom"
import FormInputCard from "../molecules/FormInputCard"
import InputDefault from "components/Input/InputDefault"
import {
  EncryptionKeys,
  encryptionController,
} from "controllers/EncryptionController"
import { setUserInfoAction } from "../stores/actions"
import { ReactComponent as IconActive } from "assets/images/icons/icon-resolve-comment-active.svg"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useState } from "react"
import { isEmpty } from "lodash"
import { STATUS_RESPONSE } from "types"

interface TwoFactorFormProps {
  handleSubmit: (type: "enable" | "disable", method: MFAMethod) => void
  userInfo: UserInfo
}
interface EnableDataEncryptionModalProps {
  handleEnableDataEncryption: (password: string) => void
  onCloseModal: () => void
}
const EnableTwoFactorForm = (props: TwoFactorFormProps) => {
  const { handleSubmit, userInfo } = props
  const onDisableTwoFactor = (method: MFAMethod) => () => {
    handleSubmit("disable", method)
    closeModal()
  }
  const openWarningModal = (method: MFAMethod) => {
    const methodText =
      method === MFAMethod.email ? "email" : "authenticator app"
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `This reduces the security level of your account and is not recommended.`,
            label: `Disable 2FA by ${methodText}`,
            content: `Are you sure you want to continue?`,
            onSubmit: onDisableTwoFactor(method),
            titleButton: `Yes, Disable 2FA`,
            styleTitle: {
              textAlign: "center",
            },
          },
        },
      })
    )
  }

  const onSwitchMFAByEmail = () => {
    if (userInfo.mfaMethods?.includes(MFAMethod.email)) {
      openWarningModal(MFAMethod.email)
    } else {
      handleSubmit("enable", MFAMethod.email)
    }
  }
  const onSwitchMFAByAuthenticatorApp = () => {
    if (userInfo.mfaMethods?.includes(MFAMethod.authenticatorApp)) {
      openWarningModal(MFAMethod.authenticatorApp)
    } else {
      handleSubmit("enable", MFAMethod.authenticatorApp)
    }
  }

  return (
    <div>
      <p className="text-sm text-black font-semibold">
        Two-factor authentication
      </p>
      <div className="mt-2 mb-6">
        <div className="flex items-center justify-between pb-2 pl-2">
          <p className="text-sm text-black font-semibold">Use the email OTP</p>
          <SwitchButtonDefault
            checked={userInfo.mfaMethods?.includes(MFAMethod.email)}
            onChange={onSwitchMFAByEmail}
          />
        </div>
        <div className="flex items-center justify-between pb-2 pl-2">
          <p className="text-sm text-black font-semibold">
            Use the authenticator app
          </p>
          <SwitchButtonDefault
            checked={userInfo.mfaMethods?.includes(MFAMethod.authenticatorApp)}
            onChange={onSwitchMFAByAuthenticatorApp}
          />
        </div>

        <p className="mt-2 mb-6">
          Secure your account with 2FA. Use a code along with your email and
          password during login to confirm it’s you.
        </p>
      </div>
    </div>
  )
}

const EnableDataEncryptionModal = (props: EnableDataEncryptionModalProps) => {
  const password = useString()
  const { handleEnableDataEncryption, onCloseModal } = props
  return (
    <ModalCustom
      renderHeader={
        <div className="flex gap-2 items-center">
          <EncryptionLockIcon />
          <Typography
            className="text-base font-semibold"
            style={{
              color: "#222222",
              lineHeight: "26px",
            }}
          >
            Enable data encryption
          </Typography>
        </div>
      }
      bodyChildren={
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6">
            <p
              style={{
                color: "#222222",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              With data encryption, all future projects you create will be
              encrypted for enhanced security. Once enabled, data encryption
              cannot be disabled.
            </p>
            <p
              style={{
                color: "#222222",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Your password is required to enable data encryption.
            </p>
            <FormInputCard title="Password" required customClass="w-full">
              <InputDefault
                type="password"
                isPassword
                value={password.value}
                onChange={(e) => password.setValue(e.target.value)}
              />
            </FormInputCard>
          </div>
          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
            <Button
              widthBtn={"100%"}
              title="Close"
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              widthBtn={"100%"}
              title={"Enable"}
              colorBtn="yellow"
              disabled={!password.value}
              onClick={() => handleEnableDataEncryption(password.value)}
            />
          </div>
        </div>
      }
    />
  )
}

const DataEncryptionForm = ({ forceEncryption = false }: any) => {
  const {
    generateMasterKeyAndPasswordHashed,
    generateVaultKeyAndBackUpKeyAndEncryptedVersion,
    storeEncryptionKey,
    generateNoteKeyAndEncryptedVersion,
  } = encryptionController()
  const userInfo = useAppSelector((state) => state.userInfo)
  const backUpKeyState = useString()
  const openRecoveryModal = useBoolean()
  const openConfirmationModal = useBoolean()
  const openDataEnableEncryptionModal = useBoolean()
  const handleEnableDataEncryption = async (password: string) => {
    const { masterKey, passwordHash } =
      await generateMasterKeyAndPasswordHashed(password, userInfo.email)
    const { vaultKey, backUpKey, encryptedBackUpkey, encryptedVaultkey } =
      generateVaultKeyAndBackUpKeyAndEncryptedVersion(masterKey)
    // Create conversation note encryption key
    const { conversationNoteKey, encryptedConversationNotekey } =
      generateNoteKeyAndEncryptedVersion(vaultKey)

    const enableEncryptionRequest: EnableEncryptionRequest = {
      password,
      passwordHash,
      encryptedBackUpkey,
      encryptedVaultkey,
      encryptedConversationNotekey,
    }
    enableEncryptionMiddleware(enableEncryptionRequest)
      .then((res) => {
        backUpKeyState.setValue(backUpKey)
        storeEncryptionKey(EncryptionKeys.userMasterKey, masterKey)
        storeEncryptionKey(EncryptionKeys.userVaultKey, vaultKey)
        storeEncryptionKey(
          EncryptionKeys.conversationNoteEncryptionKey,
          conversationNoteKey
        )
        backUpKeyState.setValue(backUpKey)
        openDataEnableEncryptionModal.setValue(false)
        openRecoveryModal.setValue(true)
        configureStore.dispatch(setUserInfoAction({ is_encrypted: true }))
        localStorage.setItem("enabled_encrypted", "1")
        toast(<LabelNotificationPage type="success" messenger={"Success"} />)
      })
      .catch((error) => {
        console.log(error)
        toast(
          <LabelNotificationPage
            type="error"
            messenger={error.response?.data.message}
          />
        )
      })
  }
  return (
    <div>
      {forceEncryption ? (
        <h4 className="text-sm text-red font-semibold mt-4 mb-4">
          To ensure the highest level of data protection, we require you to
          enable encryption mode before accessing any sensitive information.
          Encryption safeguards your data by scrambling it, making it unreadable
          to unauthorized individuals.
        </h4>
      ) : undefined}
      <div className="flex items-center justify-between">
        <p className="text-sm text-black font-semibold">Data encryption</p>
        {userInfo.is_encrypted ? (
          <div className="flex items-center gap-1 px-2 py-1 rounded-full bg-green_opacity_015">
            <IconActive />
            <span className="font-body font-semibold text-sm text-green-label">
              On
            </span>
          </div>
        ) : (
          <Button
            colorBtn="white"
            sizeBtn="small"
            title="Enable"
            onClick={() => openDataEnableEncryptionModal.setValue(true)}
          ></Button>
        )}
      </div>

      <p className="mt-2 mb-6">
        With data encryption, all future projects you create will be encrypted
        for enhanced security. Once enabled, data encryption cannot be disabled.
      </p>

      {openDataEnableEncryptionModal.value && (
        <EnableDataEncryptionModal
          handleEnableDataEncryption={handleEnableDataEncryption}
          onCloseModal={() => openDataEnableEncryptionModal.setValue(false)}
        />
      )}
      {openConfirmationModal.value ? (
        <YesNoModal
          onCancel={() => {
            openConfirmationModal.setValue(false)
          }}
          onConfirm={() => {
            openConfirmationModal.setValue(false)
            openRecoveryModal.setValue(false)
            pushTo(PATH.projectSensitiveData)
          }}
        />
      ) : undefined}
    </div>
  )
}
const TwoFactorSetting = ({ forceEncryption = false }: any) => {
  const userInfo = useAppSelector((state) => state.userInfo)
  const [qrCode, setQRCode] = useState("")
  const mfaCode = useString("")
  const isEnableMFA = useBoolean(false)
  const onUpdateTwoFactor = (type: "enable" | "disable", method: MFAMethod) => {
    if (type === "enable") {
      isEnableMFA.setValue(true)
      umamiTracking(EVENT.ENABLE_2FA)
    }
    settingTwoFactor(type, method).then((res) => {
      if (res.qrCode) {
        setQRCode(res.qrCode)
      }
    })
  }
  const onSubmit = () => {
    if (isEmpty(mfaCode.value)) {
      return toast(
        <LabelNotificationPage
          type="error"
          messenger={"MFA Code is required."}
        />
      )
    }
    verifySettingMFA(
      {
        mfaCode: mfaCode.value,
        isEnable: isEnableMFA.value,
      },
      (typeRes: STATUS_RESPONSE, messenger) => {
        if (typeRes === STATUS_RESPONSE.SUCCESS) {
          setQRCode("")
          mfaCode.setValue("")
        } else {
          toast(
            <LabelNotificationPage messenger={messenger ?? ""} type="error" />
          )
        }
      }
    )
  }
  return (
    <div className="flex flex-col">
      {!forceEncryption ? (
        <EnableTwoFactorForm
          handleSubmit={onUpdateTwoFactor}
          userInfo={userInfo}
        />
      ) : undefined}
      <DataEncryptionForm forceEncryption={forceEncryption} />
      {!isEmpty(qrCode) ? (
        <ModalCustom
          renderHeader={
            <div className="flex gap-2 items-center">
              <Typography
                className="text-base font-semibold"
                style={{
                  color: "#222222",
                  lineHeight: "26px",
                }}
              >
                Scan the QR code
              </Typography>
            </div>
          }
          bodyChildren={
            <div>
              <Typography
                className="text-sm font-normal"
                style={{
                  color: "#222222",
                }}
              >
                Use the Authenticatior app to scan the QR code. This will
                connect the Authenticator app with your Tracelium account.
              </Typography>
              <img
                style={{ width: 200, height: 200, margin: "16px auto" }}
                src={qrCode}
              />
              <Typography
                className="text-sm font-normal"
                style={{
                  color: "#222222",
                  paddingBottom: 16,
                }}
              >
                Then put the verification code from the Authenticator app here.
              </Typography>
              <FormInputCard title="MFA Code">
                <InputDefault
                  type="input"
                  value={mfaCode.value}
                  onChange={(e) => {
                    mfaCode.setValue(e.target.value)
                  }}
                />
              </FormInputCard>
              <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
                <Button
                  widthBtn={"100%"}
                  title="Cancel"
                  colorBtn="white"
                  onClick={() => {
                    setQRCode("")
                    mfaCode.setValue("")
                  }}
                />
                <Button
                  widthBtn={"100%"}
                  title="Submit"
                  colorBtn="yellow"
                  onClick={onSubmit}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  )
}
export default TwoFactorSetting
